import React, { useEffect } from "react";
import I18n, { NavLink } from "shared/lib/I18n";
import { useLocation } from "react-router-dom";
import logo from "assets/static/images/MusicStory_Logo_HOR_noTag_REV_RGB.png";
import useUser from "shared/hooks/useUser";
import { useSidebarLabelPromoDisable } from "pages/LabelPromoManagement/LabelPromoManagement.hooks";
import { useSidebarPicturesLabelAwaiting } from "pages/PicturesLabelValidation/PicturesLabelValidation.hooks";

const Sidebar = (props) => {
    const { pathname } = useLocation();
    const { user } = useUser();
    const base = pathname.match(/\/(en|fr|ja|de|ko)/g)?.shift() || "/en";
    const { nbLabelPromoDisable } = useSidebarLabelPromoDisable();
    const { nbPicturesLabelAwaiting } = useSidebarPicturesLabelAwaiting();

    useEffect(() => {
        try {
            var $sidebarContent = window.$(".js-sidebar-content");
            if (window.$("#sidebar").find(".slimScrollDiv").length !== 0) {
                $sidebarContent.slimscroll({
                    destroy: true,
                });
            }
            $sidebarContent.slimscroll({
                height: window.innerHeight,
                size: "4px",
            });
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <nav id="sidebar" className="sidebar" role="navigation">
            <div
                className="js-sidebar-content"
                style={{ paddingBottom: "74px" }}
            >
                <header className="logo hidden-sm-down">
                    <NavLink to={`/`}>
                        <img src={logo} alt="Music Story" />
                    </NavLink>
                    <span className="sub-logo">
                        <I18n t="logo" />
                    </span>
                </header>

                {user.hasRight("homepage.show") && (
                    <ul className="sidebar-nav">
                        <li>
                            <NavLink to={`/`} end>
                                <span className="icon">
                                    <i className="fas fa-desktop" />
                                </span>
                                <I18n t="base.nav.home" />
                            </NavLink>
                        </li>
                    </ul>
                )}

                <ul className="sidebar-nav">
                    <li>
                        <NavLink to={`/search`}>
                            <span className="icon">
                                <i className="fas fa-search" />
                            </span>
                            <I18n t="base.nav.search" />
                        </NavLink>
                    </li>
                </ul>

                <ul className="sidebar-nav">
                    {user.hasRight(
                        "artist.search",
                        "artist.create",
                        "artist.follow.up"
                    ) && (
                        <li
                            className={
                                pathname.includes(`${base}/artist`) ||
                                pathname.includes(`${base}/album`) ||
                                pathname.includes(`${base}/recording`)
                                    ? "active"
                                    : undefined
                            }
                        >
                            <a
                                className="collapsed"
                                href="#sidebar-artist"
                                data-toggle="collapse"
                                data-parent="#sidebar"
                            >
                                <span className="icon">
                                    <i className="fa fa-microphone" />
                                </span>
                                <I18n t="base.nav.data.artists.title" />
                            </a>
                            <ul id="sidebar-artist" className="collapse">
                                {user.hasRight("artist.search") && (
                                    <li>
                                        <NavLink to={`/artist`} end>
                                            <I18n t="base.nav.data.artists.search" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("artist.create") && (
                                    <li>
                                        <NavLink to={`/artist/create`}>
                                            <I18n t="base.nav.data.artists.create" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("artist.follow.up") && (
                                    <li>
                                        <NavLink to={`/artist/followup`}>
                                            <I18n t="base.nav.data.artists.followup" />
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    <li>
                        <a
                            className="collapsed"
                            href={`https://doc.music-story.com/`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="icon">
                                <i className="fa fa-file" />
                            </span>
                            <I18n t="base.nav.documentation.title" />
                        </a>
                    </li>
                    {user.hasRight(
                        "genre.show",
                        "genre.manage",
                        "country.show",
                        "country.manage",
                        "role.show",
                        "role.manage",
                        "label.promo.manage",
                        "misc_entity.manage"
                    ) && (
                        <li
                            className={
                                pathname.includes(`${base}/genre/view`) ||
                                pathname.includes(`${base}/country`) ||
                                pathname.includes(`${base}/role`) ||
                                pathname.includes(`${base}/misc-entity`) ||
                                pathname.includes(`${base}/label-promo`)
                                    ? "active"
                                    : undefined
                            }
                        >
                            <a
                                className="collapsed"
                                href="#sidebar-data"
                                data-toggle="collapse"
                                data-parent="#sidebar"
                            >
                                <span className="icon">
                                    <i className="fa fa-music" />
                                </span>
                                <I18n t="base.nav.data.title" />
                                {nbLabelPromoDisable > 0 && (
                                    <span className="dot dot-primary"></span>
                                )}
                            </a>
                            <ul id="sidebar-data" className="collapse">
                                {user.hasRight("role.show", "role.manage") && (
                                    <li>
                                        <NavLink to={`/role`}>
                                            <I18n t="base.nav.data.roles" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight(
                                    "country.show",
                                    "country.manage"
                                ) && (
                                    <li>
                                        <NavLink to={`/country`}>
                                            <I18n t="base.nav.data.country" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight(
                                    "genre.show",
                                    "genre.manage"
                                ) && (
                                    <li>
                                        <NavLink to={`/genre/view`}>
                                            <I18n t="base.nav.data.genres" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("label.promo.manage") && (
                                    <li>
                                        <NavLink to={`/label-promo`}>
                                            {nbLabelPromoDisable > 0 && (
                                                <span className="badge badge-pill badge-primary float-right mr-3 mt-1">
                                                    {nbLabelPromoDisable}
                                                </span>
                                            )}
                                            <I18n t="base.nav.data.labelPromo" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("misc_entity.manage") && (
                                    <li>
                                        <NavLink to={`/misc-entity`}>
                                            <I18n t="base.nav.data.misc_entity" />
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {user.hasRight("tools.artist-title") && (
                        <li
                            className={
                                pathname.includes(`${base}/tools`)
                                    ? "active"
                                    : undefined
                            }
                        >
                            <a
                                className="collapsed"
                                href="#sidebar-tools"
                                data-toggle="collapse"
                                data-parent="#sidebar"
                            >
                                <span className="icon">
                                    <i className="fa fa-wrench" />
                                </span>
                                <I18n t="base.nav.tools.title" />
                            </a>
                            <ul id="sidebar-tools" className="collapse">
                                {user.hasRight("tools.artist-title") && (
                                    <li>
                                        <NavLink to="/tools/artist-title">
                                            <I18n t="base.nav.tools.artistTitle" />
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {user.hasRight(
                        "plannings.client",
                        "plannings.manage",
                        "plannings.picture",
                        "plannings.billing",
                        "plannings.statistic"
                    ) && (
                        <li
                            className={
                                pathname.includes(`${base}/plannings`)
                                    ? "active"
                                    : undefined
                            }
                        >
                            <a
                                className="collapsed"
                                href="#sidebar-plannings"
                                data-toggle="collapse"
                                data-parent="#sidebar"
                            >
                                <span className="icon">
                                    <i className="fa fa-list" />
                                </span>
                                <I18n t="base.nav.plannings.title" />
                            </a>
                            <ul id="sidebar-plannings" className="collapse">
                                {user.hasRight("plannings.client") && (
                                    <li>
                                        <NavLink to={`/plannings/client`} end>
                                            <I18n t="base.nav.plannings.overview" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("plannings.manage") && (
                                    <li>
                                        <NavLink to={`/plannings`} end>
                                            <I18n t="base.nav.plannings.manage" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("plannings.picture") && (
                                    <li>
                                        <NavLink to={`/plannings/picture`}>
                                            <I18n t="base.nav.plannings.picture" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("plannings.billing") && (
                                    <li>
                                        <NavLink to={`/plannings/billing`}>
                                            <I18n t="base.nav.plannings.billing" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("plannings.statistic") && (
                                    <li>
                                        <NavLink to={`/plannings/statistic`}>
                                            <I18n t="base.nav.plannings.statistic" />
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {user.hasRight("charts.display", "charts.translation") && (
                        <li
                            className={
                                pathname.includes(`${base}/charts`)
                                    ? "active"
                                    : undefined
                            }
                        >
                            <a
                                className="collapsed"
                                href="#sidebar-charts"
                                data-toggle="collapse"
                                data-parent="#sidebar"
                            >
                                <span className="icon">
                                    <i className="fa fa-clipboard-list" />
                                </span>
                                <I18n t="base.nav.data.charts.title" />
                            </a>
                            <ul id="sidebar-charts" className="collapse">
                                {user.hasRight("charts.display") && (
                                    <li>
                                        <NavLink to={`/charts/display`}>
                                            <I18n t="base.nav.data.charts.display" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("charts.translation") && (
                                    <li>
                                        <NavLink to={`/charts/translation`}>
                                            <I18n t="base.nav.data.charts.translation" />
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {user.hasRight(
                        "pictures.label.gallery",
                        "pictures.label.status",
                        "pictures.label.validation",
                        "pictures.label.rejected",
                        "picture.follow.up",
                        "getty.follow.up"
                    ) && (
                        <li
                            className={
                                pathname.includes(`${base}/pictures`)
                                    ? "active"
                                    : undefined
                            }
                        >
                            <a
                                className="collapsed"
                                href="#sidebar-pictures"
                                data-toggle="collapse"
                                data-parent="#sidebar"
                            >
                                <span className="icon">
                                    <i className="fa fa-images" />
                                </span>
                                <I18n t="base.nav.pictures.title" />
                                {nbPicturesLabelAwaiting > 0 && (
                                    <span className="dot dot-primary"></span>
                                )}
                            </a>
                            <ul id="sidebar-pictures" className="collapse">
                                {user.hasRight("pictures.label.gallery") && (
                                    <li>
                                        <NavLink to={`/pictures/label/gallery`}>
                                            <I18n t="base.nav.pictures.label.gallery" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("pictures.label.status") && (
                                    <li>
                                        <NavLink to={`/pictures/label/status`}>
                                            <I18n t="base.nav.pictures.label.status" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("pictures.label.validation") && (
                                    <li>
                                        <NavLink
                                            to={`/pictures/label/validation`}
                                        >
                                            {nbPicturesLabelAwaiting > 0 && (
                                                <span className="badge badge-pill badge-primary float-right mr-3 mt-1">
                                                    {nbPicturesLabelAwaiting}
                                                </span>
                                            )}
                                            <I18n t="base.nav.pictures.label.validation" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("pictures.label.rejected") && (
                                    <li>
                                        <NavLink
                                            to={`/pictures/label/rejected`}
                                        >
                                            <I18n t="base.nav.pictures.label.rejected" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("picture.follow.up") && (
                                    <li>
                                        <NavLink to={`/curation/followup`}>
                                            <I18n t="base.nav.pictures.curation.followup" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight("getty.follow.up") && (
                                    <li>
                                        <NavLink to={`/getty/followup`}>
                                            <I18n t="base.nav.pictures.getty.followup" />
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    {user.hasRight(
                        "data.external.internationals.charts",
                        "data.external.deezer.top.songs",
                        "data.external.universal.artist"
                    ) && (
                        <li
                            className={
                                pathname.includes(`${base}/data/external`)
                                    ? "active"
                                    : undefined
                            }
                        >
                            <a
                                className="collapsed"
                                href="#sidebar-data-external"
                                data-toggle="collapse"
                                data-parent="#sidebar"
                            >
                                <span className="icon">
                                    <i className="fa fa-calendar-alt" />
                                </span>
                                <I18n t="base.nav.data.data_external.title" />
                            </a>
                            <ul id="sidebar-data-external" className="collapse">
                                {user.hasRight(
                                    "data.external.internationals.charts"
                                ) && (
                                    <li>
                                        <NavLink
                                            to={`/data/external/internationals/charts`}
                                        >
                                            <I18n t="base.nav.data.data_external.internationals_charts" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight(
                                    "data.external.deezer.top.songs"
                                ) && (
                                    <li>
                                        <NavLink
                                            to={`/data/external/deezer/top/songs`}
                                        >
                                            <I18n t="base.nav.data.data_external.deezer_top_songs" />
                                        </NavLink>
                                    </li>
                                )}
                                {user.hasRight(
                                    "data.external.universal.artist"
                                ) && (
                                    <li>
                                        <NavLink
                                            to={`/data/external/universal/artist`}
                                        >
                                            <I18n t="base.nav.data.data_external.universal_artist" />
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </li>
                    )}
                    <li
                        className={
                            pathname.includes(`${base}/api/monitoring`)
                                ? "active"
                                : undefined
                        }
                    >
                        <a
                            className="collapsed"
                            href="#sidebar-api"
                            data-toggle="collapse"
                            data-parent="#sidebar"
                        >
                            <span className="icon">
                                <i className="fa fa-project-diagram" />
                            </span>
                            <I18n t="base.nav.data.api.title" />
                        </a>
                        <ul id="sidebar-api" className="collapse">
                            <li>
                                <a
                                    href={`https://doc.music-story.com/getting-started/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <I18n t="base.nav.data.api.getting_started" />
                                </a>
                            </li>
                            <li>
                                <a
                                    href={`https://doc.music-story.com/api/reference/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <I18n t="base.nav.data.api.api_reference" />
                                </a>
                            </li>
                            <li>
                                <NavLink to={`/api/monitoring`}>
                                    <I18n t="base.nav.data.api.monitoring" />
                                </NavLink>
                            </li>
                            <li>
                                <a
                                    href={`https://status.music-story.com`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <I18n t="base.nav.data.api.status" />
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                {user.hasRight(
                    "customer.manage.matching",
                    "customer.manage.snep",
                    "customer.partner_tops.manage",
                    "cnm.repository.csa_artists.show",
                    "cnm.repository.csa_artists.manage",
                    "cnm.repository.cnm_artists_titles.show",
                    "cnm.repository.cnm_artists_titles.manage",
                    "cnm.repository.cnm_genres.show",
                    "cnm.repository.cnm_genres.manage",
                    "cnm.repository.cnm_labels.show",
                    "cnm.repository.cnm_labels.manage",
                    "cnm.repository.cnm_distributors.show",
                    "cnm.repository.cnm_distributors.manage",
                    "cnm.repository.platforms.show",
                    "cnm.repository.platforms.manage",
                    "cnm.aggregation.manage",
                    "cnm.charts.platform.show",
                    "cnm.charts.platform.manage",
                    "cnm.charts.aggregate.show",
                    "cnm.charts.aggregate.manage",
                    "cnm.report.progress.show",
                    "cnm.report.indicators.show",
                    "customer.partner_tops.manage",
                    "moodmedia.admin",
                    "api.moodmedia.search",
                    "api.moodmedia.recommendation",
                    "apple.sample.report",
                    "apple.sample.assessment.guideline"
                ) && (
                    <h5 className="sidebar-nav-title">
                        <I18n t="base.nav.title.partners" />
                    </h5>
                )}
                {user.hasRight(
                    "customer.manage.matching",
                    "customer.manage.snep",
                    "customer.partner_tops.manage",
                    "cnm.repository.csa_artists.show",
                    "cnm.repository.csa_artists.manage",
                    "cnm.repository.cnm_artists_titles.show",
                    "cnm.repository.cnm_artists_titles.manage",
                    "cnm.repository.cnm_genres.show",
                    "cnm.repository.cnm_genres.manage",
                    "cnm.repository.cnm_labels.show",
                    "cnm.repository.cnm_labels.manage",
                    "cnm.repository.cnm_distributors.show",
                    "cnm.repository.cnm_distributors.manage",
                    "cnm.repository.platforms.show",
                    "cnm.repository.platforms.manage",
                    "cnm.aggregation.manage",
                    "cnm.charts.platform.show",
                    "cnm.charts.platform.manage",
                    "cnm.charts.aggregate.show",
                    "cnm.charts.aggregate.manage",
                    "cnm.report.progress.show",
                    "cnm.report.indicators.show",
                    "customer.partner_tops.manage",
                    "moodmedia.admin",
                    "api.moodmedia.search",
                    "api.moodmedia.recommendation",
                    "apple.sample.report",
                    "apple.sample.assessment.guideline"
                ) && (
                    <ul className="sidebar-nav">
                        {user.hasRight("customer.manage.matching") && (
                            <li
                                className={
                                    pathname.includes(`${base}/matching`)
                                        ? "active"
                                        : undefined
                                }
                            >
                                <a
                                    className="collapsed"
                                    href="#sidebar-partner-matching"
                                    data-toggle="collapse"
                                    data-parent="#sidebar"
                                >
                                    <span className="icon">
                                        <i className="fa fa-cogs" />
                                    </span>
                                    <I18n t="base.nav.matching.title" />
                                </a>
                                <ul
                                    id="sidebar-partner-matching"
                                    className="collapse"
                                >
                                    <li>
                                        <NavLink to={`/matching/artist`}>
                                            <I18n t="base.nav.matching.artist" />
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/matching/album`}>
                                            <I18n t="base.nav.matching.album" />
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/matching/track`}>
                                            <I18n t="base.nav.matching.track" />
                                        </NavLink>
                                    </li>
                                    {user.hasRight(
                                        "customer.manage.matching.edito",
                                        "customer.manage.matching.data"
                                    ) && (
                                        <li>
                                            <NavLink to={`/matching/tops`}>
                                                <I18n t="base.nav.matching.tops" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight("apple.sample.manage") && (
                                        <li>
                                            <NavLink
                                                to={`/apple/sample/management`}
                                            >
                                                <I18n t="base.nav.matching.lyricsSample" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight(
                                        "customer.partner_tops.manage"
                                    ) && (
                                        <li>
                                            <NavLink
                                                to={`/matching/partner_tops`}
                                            >
                                                <I18n t="base.nav.matching.partner_tops" />
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {user.hasRight("customer.manage.snep") && (
                            <li
                                className={
                                    pathname.includes(`${base}/snep`)
                                        ? "active"
                                        : undefined
                                }
                            >
                                <a
                                    className="collapsed"
                                    href="#sidebar-partner-snep"
                                    data-toggle="collapse"
                                    data-parent="#sidebar"
                                >
                                    <span className="icon">
                                        <i className="fa fa-fire" />
                                    </span>
                                    <I18n t="base.nav.snep.title" />
                                </a>
                                <ul
                                    id="sidebar-partner-snep"
                                    className="collapse"
                                >
                                    <li>
                                        <NavLink to={`/snep/file`}>
                                            <I18n t="base.nav.snep.file" />
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        )}
                        {user.hasRight(
                            "apple.sample.report",
                            "apple.sample.assessment.guideline"
                        ) && (
                            <li
                                className={
                                    pathname.includes(
                                        `${base}/apple/sample/report`
                                    )
                                        ? "active"
                                        : undefined
                                }
                            >
                                <a
                                    className="collapsed"
                                    href="#sidebar-partner-apple"
                                    data-toggle="collapse"
                                    data-parent="#sidebar"
                                >
                                    <span className="icon">
                                        <i className="fa fa-apple-alt" />
                                    </span>
                                    <I18n t="base.nav.apple.title" />
                                </a>
                                <ul
                                    id="sidebar-partner-apple"
                                    className="collapse"
                                >
                                    {user.hasRight("apple.sample.report") && (
                                        <li>
                                            <NavLink
                                                to={`/apple/sample/report`}
                                            >
                                                <I18n t="base.nav.apple.sample.report" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight(
                                        "apple.sample.assessment.guideline"
                                    ) && (
                                        <li>
                                            <a
                                                href={`/files/Lyrics_assessment_guidelines.pdf`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <I18n t="base.nav.apple.sample.guideline" />
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {user.hasRight(
                            "cnm.repository.csa_artists.show",
                            "cnm.repository.csa_artists.manage",
                            "cnm.repository.cnm_artists_titles.show",
                            "cnm.repository.cnm_artists_titles.manage",
                            "cnm.repository.cnm_genres.show",
                            "cnm.repository.cnm_genres.manage",
                            "cnm.repository.cnm_labels.show",
                            "cnm.repository.cnm_labels.manage",
                            "cnm.repository.cnm_distributors.show",
                            "cnm.repository.cnm_distributors.manage",
                            "cnm.repository.platforms.show",
                            "cnm.repository.platforms.manage",
                            "cnm.aggregation.manage",
                            "cnm.charts.platform.show",
                            "cnm.charts.platform.manage",
                            "cnm.charts.aggregate.show",
                            "cnm.charts.aggregate.manage",
                            "cnm.report.progress.show",
                            "cnm.report.indicators.show"
                        ) && (
                            <li
                                className={
                                    pathname.includes(`${base}/cnm`)
                                        ? "active"
                                        : undefined
                                }
                            >
                                <a
                                    className="collapsed"
                                    href="#sidebar-partner-cnm"
                                    data-toggle="collapse"
                                    data-parent="#sidebar"
                                >
                                    <span className="icon">
                                        <i className="fa fa-chart-pie" />
                                    </span>
                                    <I18n t="base.nav.cnm.title" />
                                </a>
                                <ul
                                    id="sidebar-partner-cnm"
                                    className="collapse"
                                >
                                    {user.hasRight(
                                        "cnm.repository.csa_artists.show",
                                        "cnm.repository.csa_artists.manage",
                                        "cnm.repository.cnm_artists_titles.show",
                                        "cnm.repository.cnm_artists_titles.manage",
                                        "cnm.repository.cnm_genres.show",
                                        "cnm.repository.cnm_genres.manage",
                                        "cnm.repository.cnm_labels.show",
                                        "cnm.repository.cnm_labels.manage",
                                        "cnm.repository.cnm_distributors.show",
                                        "cnm.repository.cnm_distributors.manage",
                                        "cnm.repository.platforms.show",
                                        "cnm.repository.platforms.manage"
                                    ) && (
                                        <li>
                                            <NavLink to="/cnm/repositories">
                                                <I18n t="base.nav.cnm.repositories" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight(
                                        "cnm.aggregation.manage"
                                    ) && (
                                        <li>
                                            <NavLink to="/cnm/aggregation">
                                                <I18n t="base.nav.cnm.aggregation" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight(
                                        "cnm.report.consolidate.show",
                                        "cnm.report.consolidate.manage"
                                    ) && (
                                        <li>
                                            <NavLink to="/cnm/consolidation">
                                                <I18n t="base.nav.cnm.consolidation" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight(
                                        "cnm.charts.platform.show",
                                        "cnm.charts.platform.manage",
                                        "cnm.charts.aggregate.show",
                                        "cnm.charts.aggregate.manage"
                                    ) && (
                                        <li>
                                            <NavLink to={`/cnm/charts`}>
                                                <I18n t="base.nav.cnm.charts" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight(
                                        "cnm.report.progress.show",
                                        "cnm.report.indicators.show"
                                    ) && (
                                        <li>
                                            <NavLink to={`/cnm/report`}>
                                                <I18n t="base.nav.cnm.report" />
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {user.hasRight(
                            "moodmedia.admin",
                            "api.moodmedia.search",
                            "api.moodmedia.recommendation",
                            "api.recommendation"
                        ) && (
                            <li
                                className={
                                    pathname.includes(`${base}/moodmedia`)
                                        ? "active"
                                        : undefined
                                }
                            >
                                <a
                                    className="collapsed"
                                    href="#sidebar-partner-moodmedia"
                                    data-toggle="collapse"
                                    data-parent="#sidebar"
                                >
                                    <span className="icon">
                                        <i className="fa fa-music" />
                                    </span>
                                    <I18n t="base.nav.moodmedia.title" />
                                </a>
                                <ul
                                    id="sidebar-partner-moodmedia"
                                    className="collapse"
                                >
                                    {user.hasRight("moodmedia.admin") && (
                                        <li>
                                            <NavLink to={`/moodmedia/admin`}>
                                                <I18n t="base.nav.moodmedia.admin" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight("api.moodmedia.search") && (
                                        <li>
                                            <NavLink to={`/moodmedia/search`}>
                                                <I18n t="base.nav.moodmedia.search" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight(
                                        "api.moodmedia.recommendation"
                                    ) && (
                                        <li>
                                            <NavLink
                                                to={`/moodmedia/recommendation`}
                                            >
                                                <I18n t="base.nav.moodmedia.recommendation" />
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                    </ul>
                )}
                {user.hasRight(
                    "administration.manage.user",
                    "administration.manage.authors",
                    "administration.manage.rights"
                ) && (
                    <h5 className="sidebar-nav-title">
                        <I18n t="base.nav.title.admin" />
                    </h5>
                )}
                {user.hasRight(
                    "administration.manage.user",
                    "administration.manage.authors",
                    "administration.manage.rights"
                ) && (
                    <ul className="sidebar-nav">
                        {user.hasRight(
                            "administration.manage.user",
                            "administration.manage.authors"
                        ) && (
                            <li
                                className={
                                    pathname.includes(`${base}/user/manage`) ||
                                    pathname.includes(`${base}/author/manage`)
                                        ? "active"
                                        : undefined
                                }
                            >
                                <a
                                    className="collapsed"
                                    href="#sidebar-admin-user"
                                    data-toggle="collapse"
                                    data-parent="#sidebar"
                                >
                                    <span className="icon">
                                        <i className="fa fa-users-cog" />
                                    </span>
                                    <I18n t="base.nav.admin.users.title" />
                                </a>
                                <ul
                                    id="sidebar-admin-user"
                                    className="collapse"
                                >
                                    {user.hasRight(
                                        "administration.manage.user"
                                    ) && (
                                        <li>
                                            <NavLink to={`/user/manage`}>
                                                <I18n t="base.nav.admin.users.user" />
                                            </NavLink>
                                        </li>
                                    )}
                                    {user.hasRight(
                                        "administration.manage.authors"
                                    ) && (
                                        <li>
                                            <NavLink to={`/author/manage`}>
                                                <I18n t="base.nav.admin.users.author" />
                                            </NavLink>
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                        {user.hasRight("administration.manage.rights") && (
                            <li
                                className={
                                    pathname.includes(`${base}/user/role`) ||
                                    pathname.includes(`${base}/user/right`)
                                        ? "active"
                                        : undefined
                                }
                            >
                                <a
                                    className="collapsed"
                                    href="#sidebar-admin-right"
                                    data-toggle="collapse"
                                    data-parent="#sidebar"
                                >
                                    <span className="icon">
                                        <i className="fa fa-user-shield" />
                                    </span>
                                    <I18n t="base.nav.admin.rights.title" />
                                </a>
                                <ul
                                    id="sidebar-admin-right"
                                    className="collapse"
                                >
                                    <li>
                                        <NavLink to={`/user/role/manage`}>
                                            <I18n t="base.nav.admin.rights.role" />
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/user/right/manage`}>
                                            <I18n t="base.nav.admin.rights.right" />
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                        )}
                    </ul>
                )}
            </div>
        </nav>
    );
};

export default Sidebar;
